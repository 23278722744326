import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="supplier-check"
export default class extends Controller {
  static targets = ["supplierSelect", "warningMessage"]

  connect() {
    // Liste des fournisseurs avec leur statut actif/inactif
    this.suppliers = JSON.parse(this.data.get("suppliers"));
    this.checkSupplier();
  }

  checkSupplier() {
    const supplierId = this.supplierSelectTarget.value;
    const supplier = this.suppliers.find(s => s.id == supplierId);

    if (supplier && !supplier.active) {
      this.warningMessageTarget.style.display = "block";
    } else {
      this.warningMessageTarget.style.display = "none";
    }
  }
}
