import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="photo-modal"
export default class extends Controller {
  static targets = [ "image", "zoomToggle" ]

  connect() {
    this.zoomed = false
  }

  toggle() {
    if (this.zoomed) {
      this.imageTarget.style.cursor = "zoom-in"
      this.imageTarget.style.maxHeight = "70vh"
      this.imageTarget.style.width = "auto"
    } else {
      this.imageTarget.style.cursor = "zoom-out"
      this.imageTarget.style.maxHeight = "none"
      this.imageTarget.style.width = "100%"
    }
    this.zoomed = !this.zoomed
  }
}
