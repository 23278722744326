// app/javascript/controllers/excel_limit_row_check_controller.js
import { Controller } from "@hotwired/stimulus";
import readXlsxFile from 'read-excel-file';
import flatpickr from 'flatpickr'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)
const userLocale = document
  .querySelector("meta[name='user-locale']")
  .getAttribute('content')
i18n.locale = userLocale

// Connects to data-controller="excel-limit-row-check"
export default class extends Controller {
  static targets = ["fileInput", "warning"];
  static values = { maxRows: Number }

  connect() {
    this.clearWarning();
  }

  checkFile(event) {
    const file = this.fileInputTarget.files[0];
    if (!file) return;

    // Lire le fichier Excel
    readXlsxFile(file).then((rows) => {
      if (rows.length > this.maxRowsValue) {
        const message = i18n.t('i18njs.file_exceeds_max_rows', { count: rows.length, max: this.maxRowsValue });
        this.showWarning(message);
        this.clearFileInput();
      } else {
        this.clearWarning();
      }
    }).catch(error => {
      const errorMessage = i18n.t('An error occurred while reading the file, please ensure it is a valid Excel file');
      this.showWarning(errorMessage);
      this.clearFileInput();
    });
  }

  clearFileInput() {
    this.fileInputTarget.value = "";
  }

  showWarning(message) {
    this.warningTarget.innerHTML = `<span class="text-danger">${message}</span>`;
  }

  clearWarning() {
    this.warningTarget.innerHTML = "";
  }
}
