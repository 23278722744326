import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="address-autocomplete"
export default class extends Controller {
  static targets = ["input", "suggestions", "spinner"]
  timeout = null;  // <-- Variable pour gérer le debounce

  connect() {
    // Fermer la liste lorsqu'on clique en dehors
    document.addEventListener("click", this.closeSuggestions.bind(this));
    const form = this.element.closest('form');
    form.addEventListener('submit', this.validateAddressBeforeSubmit.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.closeSuggestions.bind(this));
    const form = this.element.closest('form');
    form.removeEventListener('submit', this.validateAddressBeforeSubmit.bind(this));
  }

  fetchSuggestions() {
    const query = this.inputTarget.value

    // Clear previous timeout if it's still active
    clearTimeout(this.timeout);

    // Afficher le spinner immédiatement dès que l'utilisateur commence à taper
    this.showSpinner();

    // *Debounce* : n'envoyer une requête que si l'utilisateur arrête de taper pendant 1 seconde
    this.timeout = setTimeout(() => {
      if (query.length < 3) {
        this.suggestionsTarget.innerHTML = ""
        this.suggestionsTarget.classList.remove("has-results")
        this.hideSpinner()
        return
      }

      // Récupérer les suggestions d'adresses
      let url = `/address_suggestions?query=${query}`
      if (document.body.dataset.locale) {
        url = `/${document.body.dataset.locale}${url}`
      }

      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error("La connexion avec le service d'adresse a échoué.");
          }
          return response.json();
        })
        .then(data => {
          this.suggestionsTarget.innerHTML = ""
          if (data.length > 0) {
            this.suggestionsTarget.classList.add("has-results")
            data.forEach((result) => {
              const li = document.createElement("li")
              li.classList.add("list-group-item")
              li.innerText = result.display_name  // Utiliser display_name pour afficher l'adresse complète
              li.dataset.address = JSON.stringify(result)
              li.addEventListener("click", () => this.selectAddress(result))
              this.suggestionsTarget.appendChild(li)
            })
          } else {
            this.suggestionsTarget.classList.remove("has-results")
          }
          this.hideSpinner()  // Cacher le spinner après avoir récupéré les résultats
        })
        .catch(error => {
          console.error(error);
          this.suggestionsTarget.innerHTML = "<li>Aucune suggestion trouvée ou connexion échouée.</li>";
          this.hideSpinner();  // Cacher le spinner en cas d'erreur
        });
    }, 3000);  // <-- Délai de 1000ms (1 seconde) avant d'envoyer la requête
  }

  showSpinner() {
    this.spinnerTarget.style.display = "inline-block";
  }

  hideSpinner() {
    this.spinnerTarget.style.display = "none";
  }

  selectAddress(result) {
    document.querySelector("#address_field").value = result.display_name;
    // Remplir les champs cachés
    const addressField = document.querySelector("#hidden_address_field");
    const zipCodeField = document.querySelector("#hidden_zip_code_field");
    const cityField = document.querySelector("#hidden_city_field");
    const latitudeField = document.querySelector("#hidden_latitude_field");
    const longitudeField = document.querySelector("#hidden_longitude_field");
    const countryField = document.querySelector("#hidden_country_field");
    const countryCodeField = document.querySelector("#hidden_country_code_field");

    if (addressField && zipCodeField && cityField && latitudeField && longitudeField) {
      addressField.value = `${result.address.house_number} ${result.address.road}` || "";
      zipCodeField.value = result.address.postcode || "";
      cityField.value = result.address.city || result.address.town || result.address.village || "";
      latitudeField.value = result.lat || "";
      longitudeField.value = result.lon || "";
      countryField.value = result.address.country || "";
      countryCodeField.value = result.address.country_code || "";

      this.suggestionsTarget.innerHTML = "";
      this.suggestionsTarget.classList.remove("has-results");
    }
  }

  closeSuggestions(event) {
    if (!this.element.contains(event.target)) {
      this.suggestionsTarget.innerHTML = "";
      this.suggestionsTarget.classList.remove("has-results");
    }

    if (this.inputTarget.value.trim() === "") {
      this.suggestionsTarget.innerHTML = "";
      this.suggestionsTarget.classList.remove("has-results");
    }
  }

  validateAddressBeforeSubmit(event) {
    const hiddenAddress = document.querySelector("#hidden_address_field");
    const hiddenZipCode = document.querySelector("#hidden_zip_code_field");
    const hiddenCity = document.querySelector("#hidden_city_field");

    if (!hiddenAddress.value || !hiddenZipCode.value || !hiddenCity.value) {
      event.preventDefault();
      alert("Veuillez sélectionner une adresse valide dans la liste des suggestions.");
    }
  }

  clearHiddenFields() {
    document.querySelector("#hidden_address_field").value = "";
    document.querySelector("#hidden_zip_code_field").value = "";
    document.querySelector("#hidden_city_field").value = "";
    document.querySelector("#hidden_latitude_field").value = "";
    document.querySelector("#hidden_longitude_field").value = "";
    document.querySelector("#hidden_country_field").value = "";
    document.querySelector("#hidden_country_code_field").value = "";
  }
}
