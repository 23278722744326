import { Controller } from '@hotwired/stimulus'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)
const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

export default class extends Controller {
    static targets = ['checkbox', 'checkboxReviewed', 'checkboxCashRegister']

    checkSelectedItems(checkboxes) {
        const selectedIds = Array.from(checkboxes)
            .filter((checkbox) => checkbox.checked)

        if (selectedIds.length === 0) {
            alert(i18n.t('i18njs.Please select at least one item'))
            return false
        }
        return selectedIds.map(checkbox => checkbox.dataset.requestId)
    }

    enableSelectedIngredients(event) {
        event.preventDefault()
        const selectedIds = this.checkSelectedItems(this.checkboxTargets)
        if (!selectedIds) return

        if (confirm(i18n.t('i18njs.Are you sure you want to validate adding the following products to Izychef?'))) {
            document.getElementById('enable_ingredient_ids').value = selectedIds.join(',')
            document.getElementById('enable-selected-form').submit()
        }
    }

    enableSelectedIngredientsAsReviewed(event) {
        event.preventDefault()
        const selectedIds = this.checkSelectedItems(this.checkboxReviewedTargets)
        if (!selectedIds) return

        if (confirm(i18n.t('i18njs.Are you sure you want to approve the following products and allow them to be added to the cash register and izychef?'))) {
            document.getElementById('enable_reviewed_ingredient_ids').value = selectedIds.join(',')
            document.getElementById('enable-reviewed-selected-form').submit()
        }
    }

    enableSelectedIngredientsForCashRegister(event) {
        event.preventDefault()
        const selectedIds = this.checkSelectedItems(this.checkboxCashRegisterTargets)
        if (!selectedIds) return

        if (confirm(i18n.t('i18njs.Are you sure you want to validate adding the following products to the cash register?'))) {
            document.getElementById('enable_cash_register_ingredient_ids').value = selectedIds.join(',')
            document.getElementById('enable-cash-register-selected-form').submit()
        }
    }
}
