// app/javascript/controllers/toggle_rejection_reason_controller.js
import { Controller } from "@hotwired/stimulus"
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)
const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

export default class extends Controller {
  static targets = ["checkbox", "rejectedReasonContainer", "approvalText"]

  connect() {
    this.updateApprovalText()
    this.toggleRejectionReason()
  }

  toggleRejectionReason() {
    const isChecked = this.checkboxTarget.checked
    if (isChecked) {
      this.rejectedReasonContainerTarget.classList.add("d-none")
    } else {
      this.rejectedReasonContainerTarget.classList.remove("d-none")
    }
    this.updateApprovalText()
  }

  updateApprovalText() {
    const isChecked = this.checkboxTarget.checked
    const icon = isChecked ? "fa-check-circle" : "fa-times-circle"
    const colorClass = isChecked ? "text-success" : "text-danger"
    const text = isChecked ? i18n.t('i18njs.Request approved') : i18n.t('i18njs.Request rejected')

    this.approvalTextTarget.innerHTML = `<i class="fa-solid ${icon} me-2"></i>${text}`
    this.approvalTextTarget.className = `fs-5 ms-2 ${colorClass}`
  }
}
