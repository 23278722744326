import { Controller } from '@hotwired/stimulus'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)
const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

export default class extends Controller {
    static targets = [
        'referenceName',
        'noEan',
        'supplierReference',
        'supplierReferenceContainer',
        'priceIn',
        'priceOut',
        'photos',
        'addedReferences',
    ]

    connect() {
        this.references = []
        this.savedPhotos = new Map()
        this.noEanTarget.addEventListener(
            'change',
            this.toggleSupplierReference.bind(this)
        )
    }

    toggleSupplierReference() {
        if (this.noEanTarget.checked) {
            this.supplierReferenceContainerTarget.classList.add('d-none')
            this.supplierReferenceTarget.value = ''
        } else {
            this.supplierReferenceContainerTarget.classList.remove('d-none')
        }
    }

    addReference(event) {
        event.preventDefault()
        const referenceId = Date.now()

        // Sauvegarde des photos
        const photos = Array.from(this.photosTarget.files)
        this.savedPhotos.set(referenceId, photos)

        // Création de l'objet référence
        const reference = {
            id: referenceId,
            name: this.referenceNameTarget.value,
            no_ean: this.noEanTarget.checked,
            supplier_reference: this.supplierReferenceTarget.value,
            price_in_cents: this.priceInTarget.value,
            price_out_cents: this.priceOutTarget.value,
            photos_count: photos.length, // On stocke juste le nombre de photos
        }

        // Validation
        if (!this.validateReference(reference)) return

        // Ajout à la liste
        this.references.push(reference)

        // Affichage
        this.addedReferencesTarget.insertAdjacentHTML(
            'beforeend',
            this.referenceTemplate(reference)
        )

        // Reset du formulaire
        this.resetForm()
    }

    validateReference(reference) {
        if (
            !reference.name ||
            (!reference.no_ean && !reference.supplier_reference)
        ) {
            alert(i18n.t('i18njs.Please fill in all required fields'))
            return false
        }
        return true
    }

    referenceTemplate(reference) {
        return `
          <div class="border rounded p-3 mb-3" data-reference-id="${reference.id}">
              <div class="d-flex justify-content-between align-items-center">
                  <h6 class="mb-2 text-primary">${reference.name}</h6>
                  <button type="button" class="btn btn-danger btn-sm"
                          data-action="click->reference#removeReference"
                          data-reference-target="deleteButton"
                          data-reference="${reference.id}">
                      <i class="fa-solid fa-trash"></i>
                  </button>
              </div>
              <p class="mb-1">${i18n.t('i18njs.EAN Code')}: ${reference.supplier_reference}</p>
              <p class="mb-1">${i18n.t('i18njs.Price IN')}: ${reference.price_in_cents}€ | ${i18n.t('i18njs.Price OUT')}: ${reference.price_out_cents}€</p>
              <p class="mb-0">Photos: ${reference.photos_count} photo(s)</p>

              <input type="hidden" name="ingredient_reference_request[references_attributes][${reference.id}][name]" value="${reference.name}">
              <input type="hidden" name="ingredient_reference_request[references_attributes][${reference.id}][no_ean]" value="${reference.no_ean}">
              <input type="hidden" name="ingredient_reference_request[references_attributes][${reference.id}][supplier_reference]" value="${reference.supplier_reference}">
              <input type="hidden" name="ingredient_reference_request[references_attributes][${reference.id}][price_in_cents]" value="${reference.price_in_cents}">
              <input type="hidden" name="ingredient_reference_request[references_attributes][${reference.id}][price_out_cents]" value="${reference.price_out_cents}">
          </div>
      `
    }

    createPhotoInputs(referenceId) {
        const photos = this.savedPhotos.get(referenceId) || []
        return photos
            .map(
                (photo, index) => `
      <input type="file"
             name="ingredient_reference_request[references_attributes][${referenceId}][photos][]"
             style="display: none;"
             data-reference-photo="${referenceId}-${index}">
    `
            )
            .join('')
    }

    removeReference(event) {
        const referenceId = event.currentTarget.dataset.reference
        const element = this.element.querySelector(
            `[data-reference-id="${referenceId}"]`
        )
        if (element) {
            element.remove()
            this.references = this.references.filter(
                (ref) => ref.id != referenceId
            )
            this.savedPhotos.delete(Number(referenceId))
        }
    }

    resetForm() {
        this.referenceNameTarget.value = ''
        this.noEanTarget.checked = false
        this.supplierReferenceTarget.value = ''
        this.priceInTarget.value = ''
        this.priceOutTarget.value = ''
        this.photosTarget.value = ''
        this.supplierReferenceContainerTarget.classList.remove('d-none')
    }

    // Nouveau gestionnaire de soumission de formulaire
    // Version simplifiée qui devrait fonctionner
    submit(event) {
        event.preventDefault()

        // Vérifie si les champs de référence sont remplis sans ajout à la liste
        if (
            this.referenceNameTarget.value ||
            this.supplierReferenceTarget.value ||
            this.priceInTarget.value ||
            this.priceOutTarget.value ||
            this.photosTarget.files.length > 0
        ) {
            alert(
                i18n.t(
                    'i18njs.You have entered information for a reference without adding it, please click ADD REFERENCE before submitting'
                )
            )
            return false
        }

        if (this.references.length === 0) {
            alert(i18n.t('i18njs.Add at least one reference before submitting'))
            return false
        }

        // Si tout est en ordre, envoie le formulaire
        const form = event.currentTarget.closest('form')
        const formData = new FormData(form)

        // Ajoute les photos pour chaque référence
        this.savedPhotos.forEach((photos, referenceId) => {
            photos.forEach((photo) => {
                formData.append(
                    `ingredient_reference_request[references_attributes][${referenceId}][photos][]`,
                    photo
                )
            })
        })

        // Envoie avec fetch pour gérer les fichiers correctement
        fetch(form.action, {
            method: 'POST',
            body: formData,
            headers: {
                'X-CSRF-Token': document.querySelector('[name="csrf-token"]')
                    .content,
            },
        })
            .then((response) => {
                if (response.ok) {
                    window.location.href = '/fr/ingredient_reference_requests'
                } else {
                    throw new Error('Erreur lors de la soumission')
                }
            })
            .catch((error) => {
                console.error('Error:', error)
                alert(
                    'Une erreur est survenue lors de la création des références'
                )
            })
    }
}
