import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="form-cash-register-item"
export default class extends Controller {
    static targets = [
        'destroyLink',
        'recipeFields',
        'totalCell',
        'vatIn',
        'vatOut',
        'priceGeneralInfos',
        'primaryCategory',
        'secondaryCategory',
        'ingredientFields',
        'totalCell',
        'totalItemCost',
        'percentCostCategory',
        'priceAdvisedIn',
        'priceAdvisedOut',
        'priceInside1',
        'priceInside1Span',
        'priceOutside1',
        'priceOutside1Span',
        "compositeItem"
    ]
    indexCounter = 1000

    connect() {
        this.updateAllSpanPrices()
        if (this.primaryCategoryTarget.value) {
            this.secondaryCategoryTarget.disabled = false
            if (!this.secondaryCategoryTarget.value) {
                this.primaryCategoryChange()
            }
        } else {
            this.secondaryCategoryTarget.disabled = true
        }
    }

    async itemCategoryChange(event) {
        const itemType = event.currentTarget.value
        const cashRegisterItemId =
            this.priceGeneralInfosTarget.dataset.cashRegisterItemId
        const vatIn = this.vatInTarget
        const vatOut = this.vatOutTarget
        let url = `/cash_register_items/${cashRegisterItemId}`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }

        try {
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-Token': document.querySelector(
                        'meta[name=csrf-token]'
                    ).content,
                },
                body: JSON.stringify({
                    item_type: itemType,
                    id: cashRegisterItemId,
                }),
            })

            const data = await response.json()
            this.priceGeneralInfosTarget.dataset.vatIn = data.vat_in
            this.priceGeneralInfosTarget.dataset.vatOut = data.vat_out
            vatIn.textContent = `IN (${data.vat_in * 100}%)`
            vatOut.textContent = `OUT (${data.vat_out * 100}%)`
        } catch (error) {
            console.error('Erreur:', error)
        }

        this.updatePriceAdvised()
        this.updateAllSpanPrices()
    }

    async primaryCategoryChange() {
        const primaryCategoryId = this.primaryCategoryTarget.value
        this.secondaryCategoryTarget.disabled = primaryCategoryId ? false : true
        const cashRegisterItemId = this.element.dataset.cashRegisterItemId
        let url = `/secondary_cash_register_categories/filter_by_primary?primary_category_id=${primaryCategoryId}&cash_register_item_id=${cashRegisterItemId}`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        try {
            const response = await fetch(url)
            const data = await response.json()

            const { secondary_categories } = data
            this.secondaryCategoryTarget.innerHTML = ''
            secondary_categories.forEach((category, index) => {
                const option = document.createElement('option')
                option.value = category.id
                option.text = category.name
                this.secondaryCategoryTarget.appendChild(option)
                if (index === 0) {
                    this.secondaryCategoryTarget.value = category.id
                }
            })
            this.updateSecondaryCategory()
        } catch (error) {
            console.log('Fetch error', error)
        }
    }

    updateSecondaryCategory() {
        const secondaryCategoryId = this.secondaryCategoryTarget.value
        const primaryCategoryId = this.primaryCategoryTarget.value
        const cashRegisterItemId = this.element.dataset.cashRegisterItemId
        let url = `/cash_register_items/${cashRegisterItemId}`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
            body: JSON.stringify({
              secondary_cash_register_category_id: secondaryCategoryId,
              primary_cash_register_category_id: primaryCategoryId
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                const decimalValue = parseFloat(
                    data.secondary_category.percent_cost
                )
                this.priceGeneralInfosTarget.dataset.costPercent = decimalValue
                const percentageValue = (decimalValue * 100).toFixed(2) + '%'
                this.percentCostCategoryTarget.value = percentageValue
                this.updatePriceAdvised()
                this.updateAllSpanPrices()
            })
            .catch((error) => {
                console.error('Erreur:', error)
            })
    }

    updatePriceAdvised() {
        const costPrice =
            parseFloat(this.priceGeneralInfosTarget.dataset.costPrice) / 100

        const costPercent = parseFloat(
            this.priceGeneralInfosTarget.dataset.costPercent
        )
        const vatIn = parseFloat(this.priceGeneralInfosTarget.dataset.vatIn)
        const vatOut = parseFloat(this.priceGeneralInfosTarget.dataset.vatOut)
        const priceAdvisedIn = (
            (costPrice / costPercent) *
            (1 + vatIn)
        ).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
        const priceAdvisedOut = (
            (costPrice / costPercent) *
            (1 + vatOut)
        ).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })

        this.priceAdvisedInTarget.textContent = priceAdvisedIn.replace(
            /([\d,]+)\s€/,
            '€$1'
        )
        this.priceAdvisedOutTarget.textContent = priceAdvisedOut.replace(
            /([\d,]+)\s€/,
            '€$1'
        )
    }

    addIngredients() {
      console.log('addIngredients')
        const selectedIngredients = Array.from(
            document.querySelectorAll(
                ".modal-body .form-check-input[type='checkbox']:checked"
            )
        )
        console.log('selectedIngredients', selectedIngredients)
        const ingredientFields = this.ingredientFieldsTarget
        console.log('ingredientFields', ingredientFields)
        const cashRegisterItemId = this.element.dataset.cashRegisterItemId
        console.log('cashRegisterItemId', cashRegisterItemId)
        let url = `/cash_register_items/${cashRegisterItemId}/add_ingredient`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        console.log('url', url)
        selectedIngredients.forEach((checkbox) => {
            const ingredientId = checkbox.value
            console.log('ingredientId', ingredientId)
            const ingredientName = checkbox.dataset.itemIngredientName
            console.log('ingredientName', ingredientName)

            checkbox.checked = false

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector(
                        'meta[name=csrf-token]'
                    ).content,
                },
                body: JSON.stringify({ ingredient_id: ingredientId }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 'success') {
                        // Ajouter la ligne au tableau HTML pour l'affichage
                        const newRow = ingredientFields.insertRow()
                        newRow.id = `ingredient-row-${data.ingredient_item_id}`
                        const cell1 = newRow.insertCell(0)
                        const cell2 = newRow.insertCell(1)
                        const cell3 = newRow.insertCell(2)
                        const cell4 = newRow.insertCell(3)
                        const cell5 = newRow.insertCell(4)
                        const cell6 = newRow.insertCell(5)

                        cell1.innerText = ingredientId

                        cell2.className = 'text-start align-middle'
                        const capitalizedIngredientName =
                            ingredientName.charAt(0).toUpperCase() +
                            ingredientName.slice(1)
                        const textNodeIngredient = document.createTextNode(
                            capitalizedIngredientName
                        )
                        cell2.appendChild(textNodeIngredient)
                        const linkElement = document.createElement('a')
                        linkElement.href = `/ingredients/${ingredientId}/edit`
                        linkElement.target = '_blank'
                        linkElement.rel = 'noopener noreferrer'
                        linkElement.tabIndex = '-1'
                        const iconElement = document.createElement('i')
                        iconElement.className = 'fa-regular fa-eye ms-2'
                        linkElement.appendChild(iconElement)
                        cell2.appendChild(linkElement)

                        const quantityInput = document.createElement('input')
                        quantityInput.type = 'number'
                        quantityInput.className =
                            'form-control text-center no-spin'
                        quantityInput.min = 0
                        quantityInput.placeholder = 'Quantity'
                        quantityInput.dataset.action =
                            'change->form-cash-register-item#updateQtyIngredientItem'
                        quantityInput.dataset.ingredientItemId =
                            data.ingredient_item_id
                        quantityInput.dataset.itemId = data.ingredient_item_id
                        quantityInput.dataset.itemType = 'ingredient'
                        cell3.appendChild(quantityInput)

                        // Créer le lien "destroy"
                        const destroyLink = document.createElement('a')
                        destroyLink.innerHTML =
                            '<i class="fa-solid fa-trash"></i>'
                        destroyLink.href = '#'
                        destroyLink.className = 'update-button'
                        destroyLink.dataset.formCashRegisterItemTarget =
                            'destroyLink'
                        destroyLink.dataset.action =
                            'click->form-cash-register-item#deleteItem'
                        destroyLink.dataset.ingredientItemId =
                            data.ingredient_item_id
                        destroyLink.dataset.itemId = data.ingredient_item_id
                        destroyLink.dataset.ingredientId = ingredientId
                        destroyLink.dataset.cashRegisterItemId =
                            cashRegisterItemId
                        destroyLink.dataset.itemType = 'ingredient'
                        destroyLink.dataset.confirm = 'Are you sure ?'
                        cell6.appendChild(destroyLink)

                        let unitPrice = data.unit_price / 100
                        let formattedUnitPrice = unitPrice.toLocaleString(
                            'fr-FR',
                            { style: 'currency', currency: 'EUR' }
                        )
                        formattedUnitPrice = formattedUnitPrice.replace(
                            /([\d,]+)\s€/,
                            '€$1'
                        )
                        cell4.innerText = `${formattedUnitPrice} (${data.unit_measure})` // Remplacez par la vraie valeur unit_price renvoyée par votre API

                        let total = (0).toLocaleString('fr-FR', {
                            style: 'currency',
                            currency: 'EUR',
                        })
                        cell5.innerText = total.replace(/([\d,]+)\s€/, '€$1')
                        cell5.dataset.formCashRegisterItemTarget = 'totalCell'
                        cell5.dataset.ingredientItemId = data.ingredient_item_id

                        const correspondingDiv = document.querySelector(
                            `[data-ingredient-modal-id="ingredientModalDiv_${ingredientId}"]`
                        )
                        if (correspondingDiv) {
                            correspondingDiv.classList.add('d-none')
                        }
                    }
                })
                .catch((error) => {
                    console.error('Error:', error)
                })

            this.indexCounter++
        })
        this.updateModalList()
    }

    updateQtyIngredientItem(event) {
        const itemType = event.currentTarget.dataset.itemType
        const itemId = event.currentTarget.dataset.itemId
        const quantity = event.currentTarget.value
        const targetAttribute =
            itemType === 'ingredient' ? 'ingredientItemId' : 'recipeItemId'
        const totalCell = this.totalCellTargets.find(
            (cell) => cell.dataset[targetAttribute] == itemId
        )
        let url =
            itemType === 'ingredient'
                ? `/ingredient_cash_register_items/${itemId}`
                : `/cash_register_item_recipes/${itemId}`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
            body: JSON.stringify({ id: itemId, quantity: quantity }),
        })
            .then((response) => response.json())
            .then((data) => {
                const unitPrice = parseFloat(data.unit_price / 100)
                const total = unitPrice * quantity
                let formattedTotal = total.toLocaleString('fr-FR', {
                    style: 'currency',
                    currency: 'EUR',
                })
                formattedTotal = formattedTotal.replace(/([\d,]+)\s€/, '€$1')
                totalCell.textContent = formattedTotal
                this.totalItemCostTarget.value = data.total_item_cost
                this.calculateTotalCost()
            })
            .catch((error) => {
                console.error('Error:', error)
            })
    }

    updateModalList() {
        const cashRegisterItemId = this.element.dataset.cashRegisterItemId
        let url = `/cash_register_items/${cashRegisterItemId}/edit`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
        })
            .then((response) => response.text())
            .then((html) => {
                const parser = new DOMParser()
                const doc = parser.parseFromString(html, 'text/html')

                // Mettre à jour le modal des recettes
                const recipeModalContent =
                    doc.querySelector('#RecipeModal').outerHTML
                document.querySelector('#RecipeModal').outerHTML =
                    recipeModalContent

                // Mettre à jour le modal des ingrédients (ajustez l'ID selon votre mise en page)
                const ingredientModalContent =
                    doc.querySelector('#ingredientModal').outerHTML
                document.querySelector('#ingredientModal').outerHTML =
                    ingredientModalContent
            })
            .catch((error) => {
                console.error('Erreur:', error)
            })
    }

    calculateMarginCostPercent(event) {
        if (event.currentTarget.value.includes('.')) {
            event.currentTarget.value = event.currentTarget.value.replace(
                '.',
                ','
            )
        }
        const price = parseFloat(
            event.currentTarget.value.replace('€', '').replace(',', '.')
        )
        const vatType = event.currentTarget.dataset.vatType
        let vat
        if (vatType === 'in') {
            vat = parseFloat(this.priceGeneralInfosTarget.dataset.vatIn)
        } else if (vatType === 'out') {
            vat = parseFloat(this.priceGeneralInfosTarget.dataset.vatOut)
        }
        // const vat = parseFloat(event.currentTarget.dataset.vat.replace("€", "").replace(",", "."));
        const costPrice = this.priceGeneralInfosTarget.dataset.costPrice / 100
        const inputId = event.currentTarget.id

        const relatedSpan = document.querySelector(
            `[data-related-input="${inputId}"]`
        )
        const marginCostPercent = (costPrice / (price / (1 + vat))) * 100
        if (relatedSpan) {
            this.updateSpanStyle(marginCostPercent, relatedSpan)
        }
    }

    updateAllSpanPrices() {
        const spans = document.querySelectorAll('.margin-span')
        spans.forEach((span) => {
            const inputId = span.getAttribute('data-related-input')
            const inputElement = document.getElementById(inputId)
            const vatType = inputElement.dataset.vatType
            let vat
            if (vatType === 'in') {
                vat = parseFloat(this.priceGeneralInfosTarget.dataset.vatIn)
            } else if (vatType === 'out') {
                vat = parseFloat(this.priceGeneralInfosTarget.dataset.vatOut)
            }
            // const vat = parseFloat(inputElement.dataset.vat);
            const costPrice =
                this.priceGeneralInfosTarget.dataset.costPrice / 100

            // Remplacer la virgule par un point si nécessaire
            let inputValue = inputElement.value.replace(',', '.')

            // Convertir la valeur en float
            inputValue = parseFloat(inputValue)

            const marginCostPercent =
                (costPrice / (inputValue / (1 + vat))) * 100
            if (!isFinite(marginCostPercent)) {
                span.textContent = '0%'
            } else {
                this.updateSpanStyle(marginCostPercent, span)
            }
        })
    }

    updateSpanStyle(marginCostPercent, span) {
        const costPercent =
            this.priceGeneralInfosTarget.dataset.costPercent * 100
        const redColorLimit = parseFloat(
            this.priceGeneralInfosTarget.dataset.redLimitColor
        )
        const orangeColorLimit = parseFloat(
            this.priceGeneralInfosTarget.dataset.orangeLimitColor
        )
        const darkGreenColorLimit = parseFloat(
            this.priceGeneralInfosTarget.dataset.darkGreenLimitColor
        )
        const redColor = this.priceGeneralInfosTarget.dataset.redColor
        const orangeColor = this.priceGeneralInfosTarget.dataset.orangeColor
        const greenColor = this.priceGeneralInfosTarget.dataset.greenColor
        const darkGreenColor =
            this.priceGeneralInfosTarget.dataset.darkGreenColor

        if (!isFinite(marginCostPercent)) {
            span.textContent = '0%'
            span.style.color = 'black'
            span.style.fontWeight = 'normal'
        } else {
            const difference = marginCostPercent - costPercent
            const percentageDifference = (difference / costPercent) * 100
            span.textContent = `${marginCostPercent.toFixed(1)}% ( ${difference.toFixed(0)}% )`

            if (percentageDifference > redColorLimit) {
                span.style.color = redColor
                span.style.fontWeight = 'bold'
            } else if (percentageDifference > orangeColorLimit) {
                span.style.color = orangeColor
                span.style.fontWeight = 'bold'
            } else if (
                percentageDifference < orangeColorLimit &&
                percentageDifference > darkGreenColorLimit
            ) {
                span.style.color = greenColor
                span.style.fontWeight = 'bold'
            } else if (percentageDifference < darkGreenColorLimit) {
                span.style.color = darkGreenColor
                span.style.fontWeight = 'bold'
            } else {
                span.style.color = 'black'
                span.style.fontWeight = 'normal'
            }
        }
    }

    // calculateTotalCost() {
    //     const totalCost = Array.from(this.totalCellTargets).reduce(
    //         (acc, cell) => {
    //             const costText = cell.textContent
    //                 .replace('€', '')
    //                 .trim()
    //                 .replace(/,/g, '.')
    //             const cost = parseFloat(costText) || 0

    //             return acc + cost
    //         },
    //         0
    //     )
    //     let formattedTotal = totalCost.toLocaleString('fr-FR', {
    //         style: 'currency',
    //         currency: 'EUR',
    //     })
    //     formattedTotal = formattedTotal.replace(/([\d,]+)\s€/, '€$1')
    //     this.totalItemCostTarget.value = formattedTotal
    //     this.priceGeneralInfosTarget.dataset.costPrice = totalCost * 100
    //     this.updatePriceAdvised()
    //     this.updateAllSpanPrices()
    // }

    calculateTotalCost() {
      let totalCost = Array.from(this.totalCellTargets).reduce((acc, cell) => {
          const costText = cell.textContent.replace('€', '').trim().replace(/,/g, '.')
          const cost = parseFloat(costText) || 0
          return acc + cost
      }, 0)

      if (this.compositeItemTarget.checked) { // Vérifier si "composite" est coché
          totalCost = totalCost / this.totalCellTargets.length // Calculer la moyenne au lieu de la somme
      }

      let formattedTotal = totalCost.toLocaleString('fr-FR', {
          style: 'currency',
          currency: 'EUR',
      })
      formattedTotal = formattedTotal.replace(/([\d,]+)\s€/, '€$1')
      this.totalItemCostTarget.value = formattedTotal
      this.priceGeneralInfosTarget.dataset.costPrice = totalCost * 100
      this.updatePriceAdvised()
      this.updateAllSpanPrices()
  }


    addRecipes() {
        const selectedRecipes = Array.from(
            document.querySelectorAll(
                ".modal-body .form-check-input[type='checkbox']:checked"
            )
        )
        const recipeFields = this.recipeFieldsTarget

        selectedRecipes.forEach((checkbox) => {
            const selectedRecipeId = checkbox.value
            const recipeName = checkbox.dataset.itemRecipeName
            const cashRegisterItemId = this.element.dataset.cashRegisterItemId
            checkbox.checked = false

            let url = `/cash_register_items/${cashRegisterItemId}/add_recipe`
            if (document.body.dataset.locale) {
                url = `/${document.body.dataset.locale}${url}`
            }

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector(
                        'meta[name=csrf-token]'
                    ).content,
                },
                body: JSON.stringify({ selected_recipe_id: selectedRecipeId }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 'success') {
                        const newRow = recipeFields.insertRow()
                        newRow.id = `recipe-row-${data.cash_register_item_recipe_id}`
                        const cell1 = newRow.insertCell(0)
                        const cell3 = newRow.insertCell(1)
                        const cell4 = newRow.insertCell(2)
                        const cell7 = newRow.insertCell(3)
                        const cell8 = newRow.insertCell(4)
                        const cell9 = newRow.insertCell(5)

                        cell1.innerText = selectedRecipeId

                        cell3.className = 'text-start align-middle' // Ajout de la classe pour le centrage du texte
                        const flexContainer = document.createElement('div')
                        flexContainer.className =
                            'd-flex justify-content-between'
                        const nameContainer = document.createElement('div')
                        // Capitalisation et ajout du texte
                        const capitalizedRecipeName =
                            recipeName.charAt(0).toUpperCase() +
                            recipeName.slice(1)
                        const textNode = document.createTextNode(
                            capitalizedRecipeName
                        )
                        nameContainer.appendChild(textNode)
                        // Ajout du lien avec l'icône
                        const linkElement = document.createElement('a')
                        linkElement.href = `/recipes/${selectedRecipeId}/edit` // Modifiez le chemin selon vos besoins
                        linkElement.target = '_blank'
                        linkElement.rel = 'noopener noreferrer'
                        linkElement.tabIndex = '-1'
                        const iconElement = document.createElement('i')
                        iconElement.className = 'fa-regular fa-eye ms-2'
                        linkElement.appendChild(iconElement)
                        nameContainer.appendChild(linkElement)
                        // Ajouter le conteneur du nom au conteneur flex
                        flexContainer.appendChild(nameContainer)
                        const badgeContainer = document.createElement('div')
                        // Création du badge
                        const badgeElement = document.createElement('span')
                        badgeElement.className = 'badge bg-info' // Ajoutez les classes nécessaires
                        badgeElement.textContent = 'RECIPE' // Mettez le texte du badge
                        badgeContainer.appendChild(badgeElement)
                        // Ajouter le conteneur du badge au conteneur flex
                        flexContainer.appendChild(badgeContainer)
                        // Ajouter le conteneur flex à Cell3
                        cell3.appendChild(flexContainer)

                        const quantityInput = document.createElement('input')
                        quantityInput.type = 'number'
                        quantityInput.className =
                            'form-control no-spin text-center'
                        quantityInput.min = 0
                        quantityInput.placeholder = 'Quantity'
                        quantityInput.dataset.action =
                            'change->form-cash-register-item#updateQtyIngredientItem'
                        quantityInput.dataset.recipeItemId =
                            data.cash_register_item_recipe_id
                        quantityInput.dataset.itemId =
                            data.cash_register_item_recipe_id
                        quantityInput.dataset.itemType = 'recipe'
                        cell4.appendChild(quantityInput)

                        let unitPrice = data.unit_price / 100
                        let formattedUnitPrice = unitPrice.toLocaleString(
                            'fr-FR',
                            { style: 'currency', currency: 'EUR' }
                        )
                        formattedUnitPrice = formattedUnitPrice.replace(
                            /([\d,]+)\s€/,
                            '€$1'
                        )
                        cell7.innerText = `${formattedUnitPrice} (${data.unit_measure})` // Remplacez par la vraie valeur unit_price renvoyée par votre API

                        let total = (0).toLocaleString('fr-FR', {
                            style: 'currency',
                            currency: 'EUR',
                        })
                        cell8.innerText = total.replace(/([\d,]+)\s€/, '€$1')
                        cell8.dataset.formCashRegisterItemTarget = 'totalCell'
                        cell8.dataset.recipeItemId =
                            data.cash_register_item_recipe_id

                        const destroyLink = document.createElement('a')
                        destroyLink.innerHTML =
                            '<i class="fa-solid fa-trash"></i>'
                        destroyLink.href = '#'
                        destroyLink.className = 'update-button'
                        destroyLink.dataset.formCashRegisterItemTarget =
                            'destroyLink'
                        destroyLink.dataset.action =
                            'click->form-cash-register-item#deleteItem'
                        destroyLink.dataset.recipeItemId =
                            data.cash_register_item_recipe_id
                        destroyLink.dataset.itemId =
                            data.cash_register_item_recipe_id
                        destroyLink.dataset.subRecipeId = data.recipe_id
                        destroyLink.dataset.cashRegisterItemId =
                            cashRegisterItemId
                        destroyLink.dataset.itemType = 'recipe'
                        destroyLink.dataset.confirm = 'Are you sure ?'
                        cell9.appendChild(destroyLink)

                        const correspondingDiv = document.querySelector(
                            `[data-recipe-modal-id="recipeModalDiv_${selectedRecipeId}"]`
                        )
                        if (correspondingDiv) {
                            correspondingDiv.classList.add('d-none')
                        }
                    }
                })
                .catch((error) => {
                    console.error('Error:', error)
                })

            this.indexCounter++
        })
        // this.updateModalList();
    }

    deleteItem(event) {
        event.preventDefault()
        if (!confirm(event.currentTarget.dataset.confirm)) {
            return
        }

        const itemId = event.currentTarget.dataset.itemId
        const itemType = event.currentTarget.dataset.itemType
        const targetAttribute =
            itemType === 'ingredient' ? 'ingredientItemId' : 'recipeItemId'
        const destroyCell = this.destroyLinkTargets.find(
            (cell) => cell.dataset[targetAttribute] == itemId
        )
        const recipeOrIngredientId =
            itemType === 'ingredient'
                ? destroyCell.dataset.ingredientId
                : destroyCell.dataset.subRecipeId
        let url =
            itemType === 'ingredient'
                ? `/ingredient_cash_register_items/${itemId}`
                : `/cash_register_item_recipes/${itemId}`

        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }

        fetch(url, {
            method: 'DELETE',
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'success') {
                    // Supprimer la ligne du tableau
                    console.log('success delete')
                    const correspondingDiv =
                        itemType === 'ingredient'
                            ? document.querySelector(
                                  `[data-ingredient-modal-id="ingredientModalDiv_${recipeOrIngredientId}"]`
                              )
                            : document.querySelector(
                                  `[data-recipe-modal-id="recipeModalDiv_${recipeOrIngredientId}"]`
                              )
                    if (correspondingDiv) {
                        correspondingDiv.classList.remove('d-none')
                    }
                    const row = document.getElementById(
                        `${itemType}-row-${itemId}`
                    )
                    if (row) {
                        row.remove()
                    }
                } else {
                    console.error('Erreur lors de la suppression')
                }
                this.totalItemCostTarget.value = data.total_item_cost
                this.calculateTotalCost()
                this.updateModalList()
            })
            .catch((error) => {
                console.error('Erreur:', error)
            })
    }
    //  ne pas retirer, se lance au save du form
    convertCommaToDot() {
        document.querySelectorAll('.form-control').forEach((input) => {
            if (input.value.includes(',')) {
                input.value = input.value.replace(',', '.')
            }
        })
    }
}
